import createMuiTheme, {
  ThemeOptions,
  Theme
} from "@material-ui/core/styles/createMuiTheme";
import { palette, colors, typography } from "./defaultTheme";

const themeOptions: ThemeOptions = {
  typography,
  spacing: 8,

  // First define the palette, used over all components
  palette,

  // Then override some styles
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: colors.gold,
        color: colors.whiteText
      }
    },
    MuiDrawer: {
      paperAnchorRight: {
        left: 0,
        right: 0
      }
    },

    MuiButton: {
      containedPrimary: {
        color: colors.whiteText, // theme.palette.primary.contrastText,
        backgroundColor: colors.gold, // theme.palette.primary.main,
        "&:hover": {
          backgroundColor: colors.goldDark, // theme.palette.primary.dark,
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: colors.gold // theme.palette.primary.main
          }
        }
      },
      containedSecondary: {
        color: colors.whiteText,
        backgroundColor: colors.mediumPetrol,
        "&:hover": {
          backgroundColor: "#004760", // medium petrol dark
          "@media (hover: none)": {
            backgroundColor: colors.mediumPetrol
          }
        }
      }

      /*
      containedPrimary: {
        border: `2px solid ${
          palette ? (palette.primary as SimplePaletteColorOptions).dark : "#fff"
        }`,
        borderRadius: "20px"
      }
      */
    }

    // More Mui... Elements to override
  }
};

// Mui specific settings
const theme = createMuiTheme(themeOptions);

interface SpecificThemeOptions {
  general: {
    lightPetrol: string;
    mediumPetrol: string;
    darkPetrol: string;
  };

  backgroundFront: string;
  footer: {
    background: string;
    color: string;
  };
  section: {
    dark: {
      backgroundColor: string;
      color: string;
    };
    main: {
      backgroundColor: string;
      color: string;
    };
    light: {
      backgroundColor: string;
      color: string;
    };
    common: {
      backgroundColor: string;
      color: string;
    };
  };
}

const specific: SpecificThemeOptions = {
  backgroundFront: colors.gold,
  general: {
    lightPetrol: colors.lightPetrol,
    mediumPetrol: colors.mediumPetrol,
    darkPetrol: colors.darkPetrol
  },
  footer: {
    background: colors.darkPetrol,
    color: colors.whiteText
  },

  // Change background & color(text) of sections
  section: {
    dark: {
      backgroundColor: colors.dark,
      color: theme.palette.getContrastText(colors.dark)
    },
    main: {
      backgroundColor: colors.main,
      color: theme.palette.getContrastText(colors.main)
    },
    light: {
      backgroundColor: colors.light,
      color: theme.palette.getContrastText(colors.light)
    },
    common: {
      backgroundColor: colors.common,
      color: theme.palette.getContrastText(colors.common)
    }
  }
};

export type SpecificTheme = typeof themeOptions & {
  specific: SpecificThemeOptions;
} & Theme;

export type CustomTheme = Theme & {
  specific: SpecificThemeOptions;
};

// Custom specific settings
const customTheme: CustomTheme = {
  // Everything from theme
  ...theme,
  // Specific settings for custom theme
  specific
};

export default customTheme;
