/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/* You can delete this file if you're not using it
import * as React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./src/state";
*/
require("prism-themes/themes/prism-darcula.css");

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === "undefined") {
    require("intersection-observer");
    console.log("# IntersectionObserver is polyfilled!");
  }
};

// reload page when update is available https://github.com/gatsbyjs/gatsby/issues/13410#issuecomment-486306784
export const onServiceWorkerUpdateReady = () => window.location.reload(true);

/*
export const replaceRouterComponent = ({ history }) => {
  const ConnectedRouterWrapper = ({ children }) => (
    <Provider store={store}>
      <Router history={history}>{children}</Router>
    </Provider>
  );

  return ConnectedRouterWrapper;
};
*/
